import React from 'react';
import styled from 'styled-components';

import { Link } from 'src/app/link';
import { Stack } from 'src/system2';
import * as sys from '../../system';
import * as css from './drawer-ctas.css';

const Content = styled(Stack)`
  ${(sys.wrapLast, sys.frame)}
  text-align: center;
`;

export default function Drawer({
  heading,
  content,
  contentNode,
  ctas,
  customData,
  backgroundImage
}) {
  return (
    <css.Section backgroundImageUrl={backgroundImage?.url} className="reset2">
      <Content sx={{ gap: { _: 30, m: 40, l: 50 }, py: { _: 60, m: 70, l: 80 } }}>
        <h2 css={css.heading}>{heading}</h2>
        {content && <div style={{color: '#fff'}} dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
        <ul css={css.buttonList} role="list">
          {ctas.map((cta, i) => {
            return (
              <li css={css.buttonListItem} role="listitem" key={i}>
                <Link
                  href={cta.url}
                  css={[css.button, i == 0 ? css.primaryButton : css.secondaryButton]}
                  aria-label={cta.a11yText}
                >
                  {cta.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </Content>
    </css.Section>
  );
}
