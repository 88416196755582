import styled, { css } from 'styled-components';

export * from './common.css';
import { media } from 'src/system2';
import defaultUrl from './drawer-ctas.pattern.svg';

export const Section = styled.section`
  background-color: '#5a17ee';
  background-image: ${({backgroundImageUrl}) =>
    `url(${backgroundImageUrl || defaultUrl})`
  };
  background-size: cover;
`

export const heading = css`
  color: white;
  font-weight: 900;
  max-width: 20em;
  align-self: center;
  font-size: 32px;
  line-height: 38px;
  ${media(
    'm',
    css`
      font-size: 38px;
      line-height: 44px;
    `,
  )}
  ${media(
    'l',
    css`
      font-size: 44px;
      line-height: 50px;
    `,
  )}
`;

export const button = css`
  color: white;
  font-weight: 500;
  display: inline-flex;
  background: white;
  border: 2px solid white;
  border-radius: 0.75em;
  font-size: 14px;
  padding: 0.6em 1.6em;
  &:hover,
  &:active {
    text-decoration: none;
  }
  ${media(
    'l',
    css`
      font-size: 18px;
      padding: 0.8em 2em;
    `,
  )}
`;

export const primaryButton = css`
  color: #5a17ee;
  &:hover,
  &:active {
    text-decoration: none;
    color: #340f87;
  }
`;

export const secondaryButton = css`
  color: white;
  background: none;
`;

export const buttonList = css`
  display: flex;
  justify-content: center;
  > * + * {
    margin-left: 20px;
  }
`;

export const buttonListItem = css`
  display: flex;
`;
